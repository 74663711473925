.btn {
  border-radius: 0.5rem;
}

.btn--button {
  border: none;
  padding: 0;
  background-color: transparent;
  transition: var(--transition-background-color);
}

.btn--link {
  display: block;
  text-decoration: none;
}

.btn--critical {
  background-color: var(--red600);
}

.btn--primary {
  background-color: var(--blue);
}

.btn--tertiary {
  background-color: var(--white);
}

.btn--primary:hover {
  background-color: var(--blue600);
}

.btn--primary:disabled {
  background-color: var(--gray100);
}

.btn--secondary {
  border: 1px solid var(--gray100);
}

.btn--primary .svg--fill {
  fill: var(--white);
}

.btn--primary .svg--stroke {
  stroke: var(--white);
}

.btn--secondary .svg--fill,
.btn--tertiary .svg--fill {
  fill: var(--black);
}

.btn--secondary .svg--stroke,
.btn--tertiary .svg--stroke {
  stroke: var(--black);
}

.btn:hover {
  cursor: pointer;
}

.btn:disabled {
  cursor: default;
}

.btn--critical:disabled,
.btn--primary:disabled,
.btn--tertiary:disabled,
.btn--secondary:disabled {
  background-color: var(--gray50);
  cursor: default;
}

.btn--symbol {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn--symbol:hover {
  background-color: var(--gray25);
}

.btn--symbol:active {
  background-color: var(--gray50);
}

.btn--symbol:disabled {
  background-color: transparent;
  opacity: 30%;
}

.btn--tertiary:hover {
  background-color: var(--gray25);
}

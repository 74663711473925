/*
  0.75rem=12px
  1rem=16px
  1.125rem=18px
  1.25rem=20px
  1.5rem=24px
  1.75rem=28px
  3.75rem=60px
  5rem=80px
  17.5rem=280px
*/
:root {
  /* DIMENSIONS */
  --assessment-card-width: 7.55rem;
  --assessment-card-gap: 3rem;
  --aside-width: 17rem;
  --drawer-open: 13.5rem;
  --drawer-closed: 3rem;
  --tabs-width: 15.75rem;
  --main-header-height: 3rem;
  --logo-header-height: 6.3125rem;
  --hamburger-height: 1.125rem;
  --modal-width-xs: 20.3125rem;
  --modal-width-sm: 30rem;
  --modal-width-lg: 37.5rem;
  --modal-width-xlg: 46rem;
  --modal-body-max-height-xs: none;
  --modal-body-max-height-sm: none;
  --modal-body-max-height-lg: 34.625rem;
  --tab-header-height: 3rem;
  --tab-height: 23.25rem;
  --tab-content-height: calc(var(--tab-height) - var(--tab-header-height));
  --tabs-panel-height: calc(100% - 4.5rem);

  /*TODO: refactor colors according to new design https://app.asana.com/0/1201752060654846/1203205731325420/f*/
  /* COLORS*/
  --black: #000000;
  /* BLUE */
  --blue: #1e6cfe;
  --blue25: #f6f9ff;
  --blue50: #e4edff;
  --blue100: #d9e6ff;
  --blue200: #bed4ff;
  --blue500: #4485fe;
  --blue600: #1b60e2;
  --blue700: #154cb2;
  /* CYAN */
  --cyan50: #edfbfa;
  --cyan900: #0f635e;
  /* GRAY */
  --gray00: #2c2f48;
  --gray10: #f6f8fa;
  --gray25: #f2f2f7;
  --gray50: #e5e5ea;
  --gray100: #d5d5da;
  --gray200: #bcbdc4;
  --gray300: #a2a3ae;
  --gray400: #888b99;
  --gray500: #6d7081;
  --gray700: #292e43;
  --gray800: #212336;
  --gray900: #161824;
  /* GREEN */
  --green: #45cb83;
  --green50: #ecf9f3;
  --green100: #d0f1e1;
  --green500: #5ccd98;
  --green700: #10ae65;
  --green800: #0e8950;
  --green900: #095c35;
  /* ORANGE */
  --orange50: #fff7ec;
  --orange100: #feecce;
  --orange600: #fba822;
  --orange900: #7d5006;
  /* PURPLE */
  --purple: #7a5af8;
  --purple50: #f4f2ff;
  --purple100: #e4defe;
  --purple600: #8669f9;
  --purple700: #7a5af8;
  /* RED */
  --red: #f5413d;
  --red50: #fef0ef;
  --red100: #fdd9d8;
  --red600: #f6524f;
  --red700: #e93e3a;
  --red900: #7b201f;
  /* YELLOW */
  --yellow50: #fffbeb;
  --yellow600: #fbd117;
  --yellow: #7e6600;
  /* WHITE */
  --white: #ffffff;
  --whiteAlpha30: #ffffff30;

  /* SPACING */
  --spacing00: 0.125rem;
  --spacing01: 0.25rem;
  --spacing02: 0.5rem;
  --spacing03: 0.75rem;
  --spacing04: 1rem;
  --spacing05: 1.25rem;
  --spacing06: 1.5rem;
  --spacing07: 2rem;
  --spacing08: 2.5rem;
  --spacing09: 3rem;
  --spacing12: 5rem;

  --font-family: 'Inter', sans-serif;

  /* FONT SIZE */
  --font-size-small: 0.75rem;
  --font-size-medium: 0.875rem;
  --font-size-large: 1rem;
  --font-size-xl: 1.5rem;
  --font-size-xxl: 2.25rem;

  /* FONT WEIGHT */
  --font400: 400;
  --font500: 500;
  --font600: 600;
  --font700: 700;

  /* LINE HEIGHT */
  --line-height-small: 1rem;
  --line-height-medium: 1.25rem;
  --line-height-large: 1.5rem;
  --line-height-xl: 2rem;
  --line-height-xxl: 2.75rem;

  /* BOX SHADOWS */
  --section-box-shadow-color: rgba(0, 0, 0, 0.05);
  --popper-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  --dropdown-box-shadow: 0 0.125rem 0.5rem rgba(33, 37, 41, 0.05);
  --tooltip-shadow: drop-shadow(0px 2px 8px rgba(33, 37, 41, 0.05));

  /* BORDERS */
  --input-border: 1px solid var(--gray100);
  --border-gray50: 1px solid var(--gray25);

  /* PADDING */
  --input-padding: var(--spacing02) var(--spacing03);
  --input-icon-padding: var(--spacing02) var(--spacing03) var(--spacing02)
    calc(var(--spacing01) + var(--spacing07));

  /* BORDER RADIUS */
  --border-radius: 0.5rem;
  --border-radius2: 0.125rem;
  --border-radius4: 0.25rem;
  --border-radius12: 0.75rem;

  /* TRANSITIONS */
  --transition-background-color: background-color 0.3s;

  /* GRADIENTS */
  --repeated-gray-background: repeating-linear-gradient(
    -45deg,
    var(--gray10),
    var(--gray10) 0.125rem,
    var(--white) 0.375rem,
    var(--white) 0.5rem
  );
}

body {
  font-family: var(--font-family);
  font-weight: 400;
}

img {
  width: 100%;
  height: auto;
}

/* TOASTIFY */

.toast {
  padding: var(--spacing02) !important;
  min-height: auto !important;
  font-family: var(--fontFamily) !important;
  font-size: var(--font-size-small) !important;
  line-height: var(--line-height-small) !important;
  font-weight: var(--font500) !important;
}

.toast-body {
  margin: 0 !important;
  padding: var(--spacing00) var(--spacing01) !important;
}

.toast button {
  border: none;
  color: inherit;
  background-color: transparent;
}

.typography {
  margin: 0;
  padding: 0;
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: var(--line-height);
  text-align: var(--text-align);
  color: var(--color);
  white-space: var(--white-space);
  overflow-wrap: var(--overflow-wrap);
}

.typography--cropped {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.link {
  text-decoration: none;
}

.participants-table {
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
}

/*TODO: adjust width(if needed) after all columns added*/
/*thead th:nth-child(2) {
  width: 13.5%;
  min-width: 9.75rem;
}

thead th:nth-child(3) {
  width: 30.65%;
  min-width: 22.0625rem;
}

thead th:nth-child(4) {
  width: 16%;
  min-width: 11.5rem;
}*/

.row {
  border-bottom: 1px solid var(--gray25);
}

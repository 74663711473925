.layout {
  display: flex;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.main-content {
  overflow-y: auto;
  background-color: var(--white);
}
